<template>
  <div class="container">
    <h1>抽卡小游戏</h1>
    <input type="text" v-model="newCardText" placeholder="输入卡片内容" />
    <button @click="addCard">添加卡片</button>
    <button @click="shuffleCards">打乱顺序</button>
    <button @click="clearAllCards">清除所有卡片</button>

    <div class="cards">
      <div
          v-for="(card, index) in cards"
          :key="index"
          class="card"
          :class="{ flipped: card.flipped }"
          @click="flipCard(index)"
      >
        <div class="card-inner">
          <div class="card-front"></div>
          <div class="card-back">{{ card.text }}</div>
        </div>
        <button class="delete-button" @click.stop="removeCard(index)">X</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newCardText: '',
      cards: []
    };
  },
  created() {
    const savedCards = localStorage.getItem('cards');
    if (savedCards) {
      this.cards = JSON.parse(savedCards).map(card => ({ ...card, flipped: false }));
    }
  },
  methods: {
    addCard() {
      if (this.newCardText.trim()) {
        this.cards.push({ text: this.newCardText, flipped: false });
        this.saveCards();
        this.newCardText = '';
      }
    },
    shuffleCards() {
      for (let i = this.cards.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.cards[i], this.cards[j]] = [this.cards[j], this.cards[i]];
      }
      // Reset all cards to unflipped state after shuffling
      this.cards.forEach(card => card.flipped = false);
      this.saveCards();
    },
    flipCard(index) {
      this.cards[index].flipped = !this.cards[index].flipped;
      this.saveCards();
    },
    removeCard(index) {
      this.cards.splice(index, 1);
      this.saveCards();
    },
    clearAllCards() {
      this.cards = [];
      localStorage.removeItem('cards');
    },
    saveCards() {
      localStorage.setItem('cards', JSON.stringify(this.cards));
    }
  }
};
</script>

<style scoped>
.container {
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
input[type="text"] {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background-color: #007bff;
}
button:hover {
  background-color: #0056b3;
}
.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.card {
  width: 100px;
  height: 100px;
  margin: 5px;
  perspective: 1000px;
  position: relative;
}
.card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.card.flipped .card-inner {
  transform: rotateY(180deg);
}
.card-back{
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #ccc;
  //background-image: url('../public/images/card-background.png'); /* 自定义背景图片 */
  background-size: cover;
  background-position: center;
}
.card-front {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #ccc;
  background-image: url('../public/images/card-background.png'); /* 自定义背景图片 */
  background-size: cover;
  background-position: center;
}
.card-front {
  background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景 */
  color: black;
}
.card-back {
  background-color: rgba(0, 123, 255, 0.8); /* 半透明蓝色背景 */
  color: white;
  transform: rotateY(180deg);
}
</style>



